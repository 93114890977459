<!--
 * @Description: 关于我们页面组件，未完成
 -->
<template>
    <div class="about" id="about" name="about">
        <!-- 轮播图 -->
        <div class="banner-box">
            <el-carousel :height="styleHeight">
                <el-carousel-item v-for="item in bannerlist" :key="item.id">
                    <!-- <img style="width: 100%; height:690px;" :src="$target + item.image" :alt="item.describes" /> -->
                    <el-image class="banner-img" fit="cover" :src="$target + item.image"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="about-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item> <span style="color: #CE3720;">{{ typeName }}</span> </el-breadcrumb-item>
            </el-breadcrumb>
            <div class="about-title">
                <div class="left" :style="'height: ' + programmeType.length * 85 / 16 + 'vw;'">
                    <div class="left-tit">{{ typeName }}</div>
                    <div>
                        <div class="left-li" v-for="item in programmeType" :key="item.id"
                            :class="idx == item.id ? 'active' : ''" @click="togget(item.id)">
                            {{ item.name }}</div>
                    </div>
                </div>
                <div class="right" v-loading="loading">
                    <div class="canpin-box">
                        <div class="canpin-li" @click="jumpInfo(item.id)" v-for="item in dataList" :key="item.id">
                            <!-- <img :src="$target + item.image" alt=""> -->
                            <el-image class="img" :src="$target + item.image" fit="contain"></el-image>
                            <div class="canpin-p">
                                <div class="canpin-p-t">{{ item.name }}</div>
                                <div class="canpin-p-b ">{{ item.subtitle }}</div>
                            </div>
                        </div>
                        <!-- <div class="canpin-li"></div>
                        <div class="canpin-li"></div> -->
                    </div>
                    <div class="fenye" v-show="dataList.length > 0">
                        <el-pagination background layout="prev, pager, next" @current-change="getDesignApi"
                            :current-page.sync="currentPage" :page-size.sync="pageSize" :total="total">
                        </el-pagination>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { programmeTypeApi, designApi, programmeApi, bannerApi, shejijiagongApi } from '../../api/index'
export default {
    components: {
    },
    data() {
        return {
            loading: false,
            idx: 1,
            type: 0,
            typeName: '',
            programmeType: [],
            currentPage: 1,
            pageSize: 9,
            total: 0,
            dataList: [],
            bannerlist: [],
            styleHeight: ''
        }
    },

    watch: {
        // 监听路由变化，更新路由传递了搜索条件
        $route: function (val) {
            console.log(val);
            if (val.path == "/case") {
                if (val.query.type != undefined) {
                    this.type = val.query.type
                    this.typeName = val.query.typeName
                    this.getProgrammeType()
                    this.bannerApi()
                }
            }
        }
    },
    activated() {
        this.type = this.$route.query.type
        this.typeName = this.$route.query.typeName
        this.bannerApi()
        // 案例分析和设计加工的分类
        this.getProgrammeType()
        this.setCarouselHeight(); // 初始化时设置轮播图高度
        window.addEventListener("resize", this.setCarouselHeight); // 监听窗口大小变化
    },
    methods: {
        setCarouselHeight() {
            const windowWidth = window.innerWidth;
            // const aspectRatio = 8 / 2.8; // 宽高比
            if (windowWidth >= 1920) {
                this.styleHeight = 500 / 19 + 'vw'
            } else if (windowWidth <= 750) {
                this.styleHeight = 491 / 1920 * windowWidth + 'px'
            } else {
                this.styleHeight = 500 / 1920 * windowWidth / 16 + 'vw'
            }
        },
        // 获取轮播图
        bannerApi() {
            console.log(this.type);
            let type = ''
            if (this.type == 1) {
                type = 4
            } else {
                type = 2
            }
            bannerApi({ type }).then(res => {
                this.bannerlist = res.data
            })
        },
        // 获取分类
        getProgrammeType() {
            if (this.type == 1) {
                shejijiagongApi().then(res => {
                    this.programmeType = res.data
                    this.idx = this.programmeType[0].id
                    this.getDesignApi()
                })
            } else {
                programmeTypeApi().then(res => {
                    this.programmeType = res.data
                    this.idx = this.programmeType[0].id
                    this.getDesignApi()
                })
            }

        },
        // 获取商品列表
        getDesignApi() {
            let data = {
                type_id: this.idx,
                page: this.currentPage,
                limit: this.pageSize,
            }
            if (this.type == 1) {
                designApi(data).then(res => {
                    this.loading = false
                    this.dataList = res.data.data
                    this.total = res.data.total
                })
            } else {
                programmeApi(data).then(res => {
                    this.loading = false
                    this.dataList = res.data.data
                    this.total = res.data.total
                })
            }
        },
        togget(e) {
            this.loading = true
            this.idx = e
            this.getDesignApi(e)
        },
        jumpInfo(id) {
            if (this.type == 1) {
                // 设计加工详情
                this.$router.push({ path: '/designInfo', query: { type: 1, idx: this.idx, id: id, typeName: '设计加工' } })
            } else {
                // 光学中心详情
                this.$router.push({ path: '/caseInfo', query: { type: 1, idx: this.idx, id: id, typeName: '光学中心' } })
            }
        },

    },
};
</script>
<style scoped>
.about {
    background-color: #F3F3F3;

}

.about .about-header {
    margin: 10px auto;
    padding-bottom: 100px;
    width: 1250px;
    background-color: #F3F3F3;
}

.about .about-header .about-title {
    padding-top: 20px;
    display: flex;
    margin: 0 auto;
    font-size: 28px;
}

.left {
    margin-right: 63px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 14px 14px 14px 14px;

}

.right {
    width: 1000px;
}

.left-li {
    margin-top: 17px;
    font-size: 1.3vw;
    width: 214px;
    height: 60px;
    line-height: 54px;
    padding-left: 22px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

.active::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 4px;
    height: 30px;
    background-color: #CE3720;
}

/* .active {
    color: #000014;
    background: #FDF4F5;
    border-left: 3px solid #CE3720;
} */

.right-tit {
    border-left: 3px solid #CE3720;
    padding-left: 22px;
}

.right-p {
    padding-top: 32px;
    padding-bottom: 52px;
    font-size: 20px;
    color: #9D9A96;
    line-height: 35px;
}

.canpin-box {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;

}

.canpin-li {
    margin-right: 12px;
    position: relative;
    width: 32%;
    margin-bottom: 20px;
    background-color: #fff;
    /* height: 243px; */
}

.canpin-li .img {
    width: 100%;
    height: 240px;
}

.canpin-p {
    /* position: absolute;
  top: 44px;
  left: 0;
  right: 0; */
    /* height: 155px;
  background: rgba(0, 0, 0, .41);
  z-index: 9;
  color: #FFFFFF;
  text-align: left;
  padding-left: 30px;
  font-size: 24px;
  font-weight: normal; */
    padding: 0 20px 20px;
    box-sizing: border-box;
}

.canpin-p-t {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 370px;
    font-size: 18px;
    color: #190507;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.canpin-p-b {
    /* width: 1; */
    font-size: 16px;
    color: #A5A29E;
    overflow: hidden;
    text-overflow: ellipsis;
    display: box;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.lianxi {
    display: flex;
    justify-content: space-between;
}

.lianxi-l {
    width: 50%;
    box-sizing: border-box;
    padding: 106px 72px;
    background-color: #fff;
}

.lianxi-l-lab {
    color: #000014;
    padding-left: 20px;
    border-left: 3px solid #CE3720;
}

.lianxi-l-tit {
    padding-top: 45px;
    width: 390px;
    height: 42px;
    line-height: 42px;
    font-weight: 500;
    font-size: 30px;
    color: #000014;
}

.lianxi-l-tit1 {
    font-size: 20px;
    color: #8B8B94;
    padding-bottom: 36px;
}

.lianxi-l-li {
    padding-left: 30px;
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 20px;
    color: #8B8B94;
    background-image: url('../../assets/index/adress.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 0 4px;
}

.adress {}

.tel {
    background-image: url('../../assets/index/tel.png');

}

.int {
    background-image: url('../../assets/index/guanwang.png');
}

.email {
    background-image: url('../../assets/index/email.png');
}

.lianxi-r {
    width: 50%;
    background-color: pink;
}

.head-images {
    width: 100%;
    margin-bottom: 20px;
}

.fenye {
    text-align: center;
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgba(253, 244, 245, .8);
    color: #CE3720;
    border: 1px solid #CE3720;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #CE3720;
    box-sizing: border-box;
}

.banner-img {
    width: 100%;
    height: inherit;
}
</style>
